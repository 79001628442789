import "./styles.css";
import React from "react";

function InsaneCollapseContent(props) {

    const [show, setShow] = React.useState(false);

    function onToggleContent() {
        let state = show ? false : true;
        setShow(state);
        if (props.stateChange) {
            props.stateChange(state);
        }
    }

    React.useEffect(() => {
        const controller = new AbortController();

        setShow(props.state);

        return () => {
            controller.abort();
        };
    }, [props.state]);

    return (
        <div className={"InsaneCollapseContent " + ("InsaneCollapseContent" + props.type + " ") + (show ? "active" : "")} style={props.style}>
            <div className="container">
                {props.children}
            </div>
            <div className="t-button" style={{ background: props.color ? props.color : "#666" }} onClick={() => onToggleContent()}>
                <div className="title">
                    <span>{props.title}</span>
                    <span>{show ? "▶" : "◀"}</span>
                </div>
            </div>
        </div>
    );
}

export default InsaneCollapseContent;