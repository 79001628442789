
import React from "react";
import * as Leaflet from "leaflet";
import { useMap } from 'react-leaflet';

const TrafficMap = ({ data }) => {
    const map = useMap();
    const [layerGroup, setLayerGroup] = React.useState(null);

    React.useEffect(() => {
        // สร้าง LayerGroup ถ้ายังไม่มี
        if (!layerGroup) {
            const newLayerGroup = Leaflet.layerGroup();
            setLayerGroup(newLayerGroup);
        }
    }, [layerGroup]);

    React.useEffect(() => {
        if (layerGroup) {
            // ลบ LayerGroup ออกก่อนเพื่อรีเซ็ตข้อมูล
            layerGroup.clearLayers();

            // สร้าง geoJSON และเพิ่มลงใน LayerGroup
            data.forEach((traffic) => {
                const style = {
                    color: traffic.color,
                    weight: traffic.size || 5,
                    opacity: traffic.opacity || 1,
                };

                const popup_traffic = traffic[traffic.type + "_label"];

                const geojsonFeature = {
                    type: "Feature",
                    properties: {
                        data: traffic,
                        popupContent: popup_traffic,
                    },
                    geometry: traffic.geojson,
                };

                const geojsonLayer = Leaflet.geoJSON(geojsonFeature, {
                    style: style,
                    onEachFeature: (feature, layer) => {
                        if (feature.properties && feature.properties.popupContent) {
                            layer.bindPopup(feature.properties.popupContent);
                        }

                        // if (onSelectRoute) {
                        //     layer.on({
                        //         click: () => onSelectRoute(traffic), // ส่งข้อมูล traffic
                        //     });
                        // }
                    },
                });

                // เพิ่ม geoJSON Layer ลงใน LayerGroup
                layerGroup.addLayer(geojsonLayer);
            });

            // เพิ่ม LayerGroup ลงในแผนที่
            if (!map.hasLayer(layerGroup)) {
                map.addLayer(layerGroup);
            }
        }
    }, [data, layerGroup, map ]);

    return null;
};


export default TrafficMap;

