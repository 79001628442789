
import imgNoimg from "../../../assets/images/noimg.png";
import iconRefresh from "../../../assets/icons/refresh.png";

import * as Leaflet from "leaflet";

const iconPin = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/pin-map.png'),
    iconAnchor: new Leaflet.Point(15, 44),
    popupAnchor: new Leaflet.Point(0, -44),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(30, 44)
});

const pin_square_border = `
<svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
        viewBox="0 0 256 256"
        style="enable-background:new 0 0 256 256;"
        xml:space="preserve">
    <g>
        <rect x="5" y="5" class="st0" width="246" height="246" fill="{mapIconColor}"/>
        <path d="M246,10v236H10V10H246 M256,0H0v256h256V0L256,0z"/>
    </g>
</svg>
`

const pin_circle_border = `
<svg 
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" 
    x="0px" 
    y="0px"
    viewBox="0 0 256 256"
    style="enable-background:new 0 0 256 256;"
    xml:space="preserve">
    <g>
        <circle class="st0" cx="128" cy="128" r="123" fill="{mapIconColor}"/>
        <path d="M128,10c31.5,0,61.2,12.3,83.4,34.6C233.7,66.8,246,96.5,246,128s-12.3,61.2-34.6,83.4C189.2,233.7,159.5,246,128,246
            s-61.2-12.3-83.4-34.6C22.3,189.2,10,159.5,10,128s12.3-61.2,34.6-83.4C66.8,22.3,96.5,10,128,10 M128,0C57.3,0,0,57.3,0,128
            s57.3,128,128,128s128-57.3,128-128S198.7,0,128,0L128,0z"/>
    </g>
</svg>
`

const pin_custom_image = `<img src="{image}" style="{style}" width="20" height="40"/>`

const iconPinIntersect = (color) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_square_border, {
        mapIconColor: (color ? color : '#888') /// กำหนดสีผ่านตัวแปล
    }),
    iconAnchor: new Leaflet.Point(7, 7),
    popupAnchor: new Leaflet.Point(0, -7),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(14, 14)
});

const iconPinIntersectCircle = (color) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_circle_border, {
        mapIconColor: (color ? color : '#888') /// กำหนดสีผ่านตัวแปล
    }),
    iconAnchor: new Leaflet.Point(9, 9),
    popupAnchor: new Leaflet.Point(0, -9),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(18, 18)
});

const iconPinSensor = (status) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_custom_image, {
        image: require('../../../assets/icons/sensor.png'),
        style: (status === true) ? `` : `filter: grayscale(100%)`
    }),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinCCTV = (status) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_custom_image, {
        image: require('../../../assets/icons/cctv.png'),
        style: (status === true) ? `` : `filter: grayscale(100%)`
    }),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinBluetooth = (status) => new Leaflet.divIcon({
    className: "leaflet-data-marker",
    html: Leaflet.Util.template(pin_custom_image, {
        image: require('../../../assets/icons/bluetooth.png'),
        style: (status === true) ? `` : `filter: grayscale(100%)`
    }),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinPort = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/transport - port.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinAirport = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/transport - airport.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinBus = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/transport - bus.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinIndustrial = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/transport - industrial.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});

const iconPinTrain = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/transport - train.png'),
    iconAnchor: new Leaflet.Point(10, 10),
    popupAnchor: new Leaflet.Point(0, -10),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(20, 20)
});



const iconPinTruck = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/truck.png'),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});

const iconPinIncident = new Leaflet.Icon({
    iconUrl: require('../../../assets/icons/incident.png'),
    iconAnchor: new Leaflet.Point(20, 40),
    popupAnchor: new Leaflet.Point(0, -40),
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new Leaflet.Point(40, 40)
});


export {
    imgNoimg,
    iconRefresh,
    iconPin,
    pin_square_border,
    pin_circle_border,
    iconPinIntersect,
    iconPinIntersectCircle,
    iconPinSensor,
    iconPinCCTV,
    iconPinBluetooth,
    iconPinPort,
    iconPinAirport,
    iconPinBus,
    iconPinIndustrial,
    iconPinTrain,
    iconPinTruck,
    iconPinIncident
}