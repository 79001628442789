import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Popup } from 'react-leaflet';

import VideoPlayerComponent from "./video-player";
import LineChart from "./line-chart";

import { imgNoimg, iconRefresh } from "./assets";

const PopupSensor = (props) => {
    // { pin, timeData, source }
    let pin = props.pin;
    let timeData = props.timeData;
    let source = props.source;

    const navigate = useNavigate();

    const [stateTab, setStateTab] = React.useState("stream");
    const [sourceStreamImage, setSourceStreamImage] = React.useState(null);

    const [snapImage_in, set_snapImage_in] = React.useState(pin.stream_url_inbound);
    const [snapImage_out, set_snapImage_out] = React.useState(pin.stream_url_outbound);

    const onNavigatePage = (path) => {
        navigate(path);
    };

    function onRefreshSnapImage() {
        set_snapImage_in(pin.stream_url_inbound + "?" + Date.now());
        set_snapImage_out(pin.stream_url_outbound + "?" + Date.now());
    }

    React.useEffect(() => {

        let find_source = source.filter((s) => pin.source === s.id);
        if (find_source.length > 0) {
            setSourceStreamImage(find_source[0]);
        }

    }, [props]);

    React.useEffect(() => {
        const RefreshInternal = setInterval(() => {
            set_snapImage_in(pin.stream_url_inbound + "?" + Date.now());
            set_snapImage_out(pin.stream_url_outbound + "?" + Date.now());
        }, 60000);
        return () => {
            clearInterval(RefreshInternal);
        };
    }, []);

    return (
        <Popup maxWidth={330} minWidth={330}>
            <div className="popup-sensor-container">

                <div className="source-stream">
                    {
                        (sourceStreamImage && sourceStreamImage.icon) ? (
                            <img src={sourceStreamImage.icon} />
                        ) : (null)
                    }
                    <div className="source-stream-detail">
                        {
                            (sourceStreamImage && sourceStreamImage.title) ? (
                                <span><b>{sourceStreamImage.title}</b></span>
                            ) : (null)
                        }

                        {/** show & close button report card popup */}
                        {pin.online ? (
                            <button
                                className="bt-report"
                                style={{ background: ((!pin.report) ? "gray" : "") }}
                                onClick={() => {
                                    if (pin.report) {
                                        // onNavigatePage("/report?report_id=SPEED&sensor_id=" + pin.sensor_id);
                                        pin.on_report(pin.sensor_id);
                                    }
                                }}>REPORT</button>
                        ) : (null)}
                        {/* <button className="bt-report" onClick={() => onCheckData(pin)}>DATA</button> */}
                    </div>

                </div>

                <div className="header">
                    {/* header-box (pin.code)*/}
                    <div className="code">{pin.name}</div>
                    <div className="tab-bt-container">
                        <div className={"bt " + ((stateTab === "stream") ? "active" : "")} onClick={() => setStateTab("stream")}>Stream</div>
                        <div className={"bt " + ((stateTab === "detail") ? "active" : "")} onClick={() => setStateTab("detail")}>Detail</div>
                        <div className={"bt " + ((stateTab === "chart") ? "active" : "")} onClick={() => setStateTab("chart")}>Chart</div>
                    </div>
                </div>
                {
                    (stateTab === "detail") ? (
                        <div className="content">
                            <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.sensor_detail }}></div>
                        </div>
                    ) : (null)
                }
                {
                    (stateTab === "stream") ? (
                        <div className="content">
                            <span style={{ marginBottom: 5 }}>
                                Stream Type : <b>{pin.stream_type}</b>
                            </span>
                            {
                                (pin.stream_type === "html" || pin.stream_type === "snapshot") ? (
                                    <button className="bt-report" onClick={() => onRefreshSnapImage()} style={{
                                        marginLeft: 10,
                                        marginTop: -5
                                    }}>
                                        <img src={iconRefresh} width={20} height={20} />
                                    </button>
                                ) : (null)
                            }
                            <br />
                            {
                                pin.stream_url_inbound ? (
                                    <Fragment>
                                        <b>{pin.desc_inbound}</b><br />
                                        {
                                            (pin.stream_type === "rtsp") ? (
                                                <VideoPlayerComponent url={pin.stream_url_inbound} />
                                            ) : (pin.stream_type === "html" || pin.stream_type === "snapshot") ? (
                                                <img
                                                    src={snapImage_in}
                                                    className="img-html-content-video"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.src = imgNoimg;
                                                    }} />
                                            ) : (
                                                <VideoPlayerComponent url={pin.stream_url_inbound} />
                                            )
                                        }
                                    </Fragment>
                                ) : (null)
                            }
                            <br /><br />
                            {
                                pin.stream_url_outbound ? (
                                    <Fragment>
                                        <b>{pin.desc_outbound}</b><br />
                                        {
                                            (pin.stream_type === "rtsp") ? (
                                                <VideoPlayerComponent url={pin.stream_url_outbound} />
                                            ) : (pin.stream_type === "html" || pin.stream_type === "snapshot") ? (
                                                <img
                                                    src={snapImage_out}
                                                    className="img-html-content-video"
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.src = imgNoimg;
                                                    }} />
                                            ) : (
                                                <VideoPlayerComponent url={pin.stream_url_outbound} />
                                            )
                                        }
                                    </Fragment>
                                ) : (null)
                            }
                            <br />
                        </div>
                    ) : (null)
                }
                {
                    (stateTab === "chart") ? (
                        <div className="content">
                            {/* <span>Date :  <b>{data_chart.title} [ {data_chart.date} ]</b></span> */}
                            <LineChart pin={pin} timeData={timeData}></LineChart>
                        </div>
                    ) : (null)
                }
            </div>
        </Popup>
    )
}

export default PopupSensor;