


import React from "react";
import ReactHlsPlayer from 'react-hls-player';

const VideoPlayerComponent = (props) => {
    const playerRef = React.useRef();

    function playVideo() {
        playerRef.current.play();
    }

    // function pauseVideo() {
    //     playerRef.current.pause();
    // }

    // function toggleControls() {
    //     playerRef.current.controls = !playerRef.current.controls;
    // }

    React.useEffect(() => {
        if (playerRef.current) {
            playVideo();
        }
    }, []);

    return (
        <ReactHlsPlayer
            style={{ width: "-webkit-fill-available" }}
            playerRef={playerRef}
            src={props.url}
            playing={"true"}
            controls
            config={{
                file: {
                    attributes: {
                        controlsList: "nofullscreen",
                    },
                },
            }}
        />
    );
}

export default VideoPlayerComponent;