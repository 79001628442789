
import React from "react";
import * as Leaflet from "leaflet";
import 'leaflet.markercluster';
import { useMap } from 'react-leaflet';
import { iconPinIncident } from "./assets";

const MarkerClusterGroup = ({ data }) => {
    const map = useMap();
    const [Layers, set_Layers] = React.useState(null);

    React.useEffect(() => {
        if (!Layers) {
            let layers = new Leaflet.MarkerClusterGroup({
                iconCreateFunction: function (cluster) {
                    let color = "green";
                    if (cluster.getChildCount() > 10) {
                        color = "yellow";
                    }
                    if (cluster.getChildCount() > 100) {
                        color = "orange";
                    }
                    if (cluster.getChildCount() > 200) {
                        color = "red";
                    }
                    return Leaflet.divIcon({
                        className: ("marker-cluster " + color),
                        html: '<b>' + cluster.getChildCount() + '</b>',
                        iconAnchor: new Leaflet.Point(20, 20),
                        popupAnchor: new Leaflet.Point(0, -20),
                        shadowUrl: null,
                        shadowSize: null,
                        shadowAnchor: null,
                        iconSize: new Leaflet.Point(40, 40)
                    });
                }
            });
            set_Layers(layers);
        }

    }, []);

    React.useEffect(() => {
        if (Layers) {
            if (data.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    let position = [data[i].lat, data[i].lon];
                    let option = {
                        icon: iconPinIncident
                    }
                    let marker = Leaflet.marker(position, option)
                    let popup = (
                        "เวลา : <b>"
                        + data[i].datetime + "</b><br />"
                        + "รายละเอียด : <b>"
                        + data[i].description + "</b>"
                    );
                    marker.bindPopup(popup);

                    Layers.addLayer(marker);
                }
                map.addLayer(Layers);
            } else {
                Layers.clearLayers();
            }
        }

    }, [data, Layers]);

    return null;
}

export default MarkerClusterGroup;