import React from "react";
import { useMap } from 'react-leaflet';

const CenterMap = ({ lat, lng, zoom }) => {
    const map = useMap();
    React.useEffect(() => {
        map.setView([lat, lng], zoom);
    }, [lat, lng, zoom]);
    return null;
}

const FitBoundsMap = ({ arr_lat_lon }) => {
    const map = useMap();
    React.useEffect(() => {

        if (arr_lat_lon && (arr_lat_lon.length > 0)) {
            map.fitBounds(arr_lat_lon);
        }

    }, [arr_lat_lon]);

    return null;
}

export {
    CenterMap,
    FitBoundsMap
}