import "./styles.css";
import React, { Fragment } from "react";

import "../../../node_modules/leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, Polyline, ZoomControl, Circle, GeoJSON } from 'react-leaflet';

import {
    iconPinIntersect,
    iconPinIntersectCircle,
    iconPinSensor,
    iconPinCCTV,
    iconPinBluetooth,
    iconPinPort,
    iconPinAirport,
    iconPinBus,
    iconPinIndustrial,
    iconPinTrain,
    iconPinTruck,
} from "./component/assets";

import {
    CenterMap,
    FitBoundsMap
} from "./component/utility";

import TrafficMap from "./component/traffic-map";
import MarkerClusterGroup from "./component/marker-cluster";
import PopupSensor from "./component/popup-sensor";

function MyApp(props) {

    const [center, setCenter] = React.useState(props.center);
    const [zoom, setZoom] = React.useState(props.zoom);

    const [PolygonData, setPolygonData] = React.useState(null);
    const [PolylineData, setPolylineData] = React.useState(null);
    const [CircleData, setCircleData] = React.useState(null);
    const [TrafficData, setTrafficData] = React.useState(null);
    const [PinmapData, setPinmapData] = React.useState(null);
    const [ClusterData, setClusterData] = React.useState(null);

    const [timeData, setTimeData] = React.useState(null);
    const [sourceData, setSourceData] = React.useState([]);

    React.useEffect(() => {
        const controller = new AbortController();

        if (props.center && props.zoom) {
            setCenter(props.center);
            setZoom(props.zoom);
        }

        return () => {
            controller.abort();
        };
    }, [props.center, props.zoom]);
    
    React.useEffect(() => {
        const controller = new AbortController();

        if (props.polygon) { setPolygonData(props.polygon) }
        if (props.circle) { setCircleData(props.circle) }
        if (props.traffic) { setTrafficData(props.traffic) }
        if (props.pinmap) { setPinmapData(props.pinmap) }
        if (props.cluster) { setClusterData(props.cluster) }
        if (props.source) { setSourceData(props.source) }

        if (props.time) {
            if (props.time !== "LATEST") {
                setTimeData(props.time)
            } else {
                setTimeData(null)
            }
        } else {
            setTimeData(null)
        }

        return () => {
            controller.abort();
        };
    }, [
        props.polygon,
        props.circle,
        props.traffic,
        props.pinmap,
        props.cluster,
        props.source,
    ]);

    React.useEffect(() => {
        const controller = new AbortController();

        if (props.polyline) {
            setPolylineData(null);
            setTimeout(() => {
                setPolylineData(props.polyline);
            }, 1);
        }

        return () => {
            controller.abort();
        };
    }, [props.polyline]);


    return (
        <MapContainer
            center={center}
            zoom={zoom}
            zoomControl={false}
            scrollWheelZoom={true}
            style={{ width: "100%", height: "auto", flexGrow: 1, flexBasis: 0 }}>

            <CenterMap lat={center[0]} lng={center[1]} zoom={zoom} />

            <FitBoundsMap arr_lat_lon={props.fitBoundsRef} />
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {
                TrafficData ? (
                    <TrafficMap data={TrafficData}></TrafficMap>
                ) : (null)
            }
            {
                ClusterData ? (
                    <MarkerClusterGroup data={ClusterData}></MarkerClusterGroup>
                ) : (null)
            }

            <div name="polygon-layer" style={{ zIndex: 501 }}>
                {
                    PolygonData ? (
                        PolygonData.map((polygon, index) => {
                            let color = "purple"; // color base map polygon
                            let weight = 10;

                            if (polygon.pin_type) {
                                weight = 1;
                            }

                            if (polygon.place_type === "train") {
                                weight = 5;
                                color = "#7f5101";
                            }

                            return (

                                <GeoJSON
                                    key={index}
                                    color={color}
                                    data={polygon.geojson}
                                    weight={weight}
                                    fillOpacity={0.1}
                                    opacity={polygon.opacity ? polygon.opacity : 1}
                                >
                                    <Popup>
                                        <b> {polygon.name} </b>
                                        {
                                            polygon.label ? (
                                                <>
                                                    <br /><b> {polygon.label} </b>
                                                </>
                                            ) : (null)
                                        }

                                    </Popup>
                                </ GeoJSON>
                            );
                        })
                    ) : (null)
                }
            </div>

            <div name="polyline-layer" style={{ zIndex: 502 }}>
                {
                    PolylineData ? (
                        PolylineData.map((polyline, index) => {
                            return (
                                <Polyline
                                    key={index}
                                    weight={polyline.size ? polyline.size : 5}
                                    color={polyline.color}
                                    opacity={polyline.opacity ? polyline.opacity : 1}
                                    positions={polyline.geojson.coordinates}>
                                    {
                                        (polyline.type_polyline === "od") ? (

                                            <Popup>
                                                <div className="inner-html" dangerouslySetInnerHTML={{ __html: polyline.od_label }}></div>
                                            </Popup>
                                        ) : (null)
                                    }
                                </Polyline>
                            );
                        })
                    ) : (null)
                }
            </div>
            <div name="circle-layer" style={{ zIndex: 503 }}>
                {
                    CircleData ? (
                        CircleData.map((circle, index) => {
                            return (
                                (circle.pin_type === "intersect") ? (

                                    <Circle
                                        key={index}
                                        center={[circle.lat, circle.lon]}
                                        pathOptions={{ fillColor: "#9605FB", color: "#9605FB", fillOpacity: "0.5" }}
                                        radius={circle.raduis_m ? circle.raduis_m : 100}
                                    >
                                        <Popup>
                                            {circle.desc}<br />
                                            {circle.name}
                                        </Popup>
                                    </Circle>
                                ) : (
                                    <Circle
                                        key={index}
                                        center={[circle.lat, circle.lon]}
                                        pathOptions={{ fillColor: circle.color ? circle.color : 'red' }} // color of circle
                                        radius={circle.raduis_m}
                                    >
                                        <Popup>
                                            <b> {circle.name} </b><br />
                                            <span style={{ color: "blue" }}>{circle.place_type}</span>
                                        </Popup>
                                    </Circle>
                                )
                            );
                        })
                    ) : (null)
                }
            </div>
            <div name="marker-layer" style={{ zIndex: 504 }}>
                {
                    PinmapData ? (
                        PinmapData.map((pin, index) => {
                            let iconMarker = iconPinTruck;
                            switch (pin.pin_type) {

                                case "intersect":
                                    iconMarker = iconPinIntersect(pin.color);
                                    break;
                                case "intersect_trafficlight":
                                    iconMarker = iconPinIntersectCircle(pin.color);
                                    break;
                                case "radar":
                                    iconMarker = iconPinSensor(pin.online); {/**/ }
                                    break;
                                case "cctv":
                                    iconMarker = iconPinCCTV(pin.online);
                                    break;
                                case "bluetooth":
                                    iconMarker = iconPinBluetooth(pin.online);
                                    break;
                                case "industrialestate":
                                    iconMarker = iconPinIndustrial;
                                    break;
                                case "port":
                                    iconMarker = iconPinPort;
                                    break;
                                case "airport":
                                    iconMarker = iconPinAirport;
                                    break;
                                case "bus":
                                    iconMarker = iconPinBus;
                                    break;
                                case "train":
                                    iconMarker = iconPinTrain;
                                    break;

                                default:
                                    break;
                            }

                            return ((pin.none_action) ? (
                                <Marker
                                    key={index}
                                    icon={iconMarker}
                                    position={[pin.lat, pin.lon]}

                                ></Marker>
                            ) : (
                                <Marker
                                    key={index}
                                    icon={iconMarker}
                                    position={[pin.lat, pin.lon]}
                                    ref={e => pin.markerRef = { current: e }}  // Set the marker reference เปิด ป๊อบ
                                >
                                    {   /* Control Box data  ()*/
                                        (pin.pin_type === "radar" || pin.pin_type === "bluetooth" || pin.pin_type === "cctv") ? (
                                            <PopupSensor pin={pin} timeData={timeData} source={sourceData}></PopupSensor>
                                        ) : ((pin.pin_type === "intersect") || (pin.pin_type === "intersect_trafficlight")) ? (
                                            <Popup>
                                                {
                                                    pin.data_dashboard ? (
                                                        <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.data_dashboard.intersect_label }}></div>
                                                    ) : (
                                                        pin.desc ? (
                                                            <Fragment>
                                                                {pin.name}<br />
                                                                <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.desc }}></div>
                                                            </Fragment>
                                                        ) : (
                                                            <div className="inner-html" dangerouslySetInnerHTML={{ __html: pin.intersect_label }}></div>
                                                        )

                                                    )
                                                }
                                            </Popup>
                                        ) : (
                                            (pin.pin_type === "industrialestate") ||
                                            (pin.pin_type === "airport") ||
                                            (pin.pin_type === "port") ||
                                            (pin.pin_type === "bus") ||
                                            (pin.pin_type === "train")
                                        ) ? (
                                            <Popup>
                                                {pin.name}
                                            </Popup>
                                        ) : (
                                            <Popup >
                                                ประเภทรถบรรทุก: {pin.vehicle_type}<br />
                                                เวลา: {pin.time}<br />
                                                ความเร็ว: {parseFloat(pin.speed_kph).toFixed(1)} กม/ชม.<br />
                                                <span style={{ color: "red" }}>{pin.pin_type}</span>
                                            </Popup>
                                        )
                                    }
                                </Marker>)
                            );
                        })
                    ) : (null)
                }
            </div>

            <ZoomControl position="bottomright" />

        </MapContainer>
    )
}

export default MyApp;