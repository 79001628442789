import "./styles.css";
import React from "react";

function InsaneCollapseContentBottom(props) {

    const [show, setShow] = React.useState(false);

    function onToggleContent() {
        let state = show ? false : true;
        setShow(state);
        if (props.stateChange) {
            props.stateChange(state);
        }
    }

    React.useEffect(() => {
        const controller = new AbortController();

        setShow(props.state);

        return () => {
            controller.abort();
        };
    }, [props.state]);

    return (
        <div className={"InsaneCollapseContentBottom " + props.className + " " + (show ? "active" : "")} style={props.style}>
            <div className="t-button" style={{ background: props.color ? props.color : "#666" }} onClick={() => onToggleContent()}>
                <div className="title"> {props.title} <span className="state-icon">{show ? "▼" : "▲"}</span></div>
            </div>
            <div className="container">
                {props.children}
            </div>
        </div>
    );
}

export default InsaneCollapseContentBottom;