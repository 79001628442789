

import React, { Fragment } from "react";

import Spinner from "../../../component/spinner";

import { Chart } from "react-google-charts";
import { HttpRequest } from "../../../service/HttpRequest";

const LineChart = ({ pin, timeData }) => {

    const [DATA_CHART, SET_DATA_CHART] = React.useState(null);
    const [TITLE_CHART, SET_TITLE_CHART] = React.useState("");

    const [TIME_DATA_RANGE, SET_TIME_DATA_RANGE] = React.useState([
        {
            id: "LATEST",
            title: "เวลาล่าสุด",
            start: null,
            stop: null
        },
        {
            id: "AM",
            title: "AM Peak (06.00-09.00)",
            start: 6,
            stop: 9
        },
        {
            id: "MD",
            title: "MD Peak (11.00-13.00)",
            start: 11,
            stop: 13
        },
        {
            id: "PM",
            title: "PM Peak (16.00-19.00)",
            start: 16,
            stop: 19
        }
    ]);

    function generateDataChart(data) {

        let data_chart = [[data[0].x_label]];
        let tick_chart = []

        let h_label = (((data.length > 0) && data[0].x_label) ? data[0].x_label : "");
        let v_label = (((data.length > 0) && data[0].y_label) ? data[0].y_label : "");

        let max_h_value = 0;

        for (let i = 0; i < data.length; i++) {
            const graph = data[i];
            data_chart[0].push(graph.title);
            let stack_x_axis = null;

            for (let j = 0; j < graph.values.length; j++) {
                let x_axis = Math.floor(graph.values[j].x_float);

                let x_axis_label = (graph.values[j].x_float).toString();
                let y_axis_label = (graph.values[j].y_value);

                if (graph.values[j].y > max_h_value) {
                    max_h_value = graph.values[j].y;
                }

                if (i === 0) {
                    if (stack_x_axis !== x_axis) {
                        stack_x_axis = x_axis;
                        tick_chart.push({ v: graph.values[j].x, f: x_axis_label });
                    } else {
                        tick_chart.push({ v: graph.values[j].x, f: "" });
                    }
                }

                if (i > 0) {
                    data_chart[j + 1].push({ v: graph.values[j].y, f: y_axis_label });
                } else {
                    data_chart.push([{ v: graph.values[j].x, f: graph.values[j].x_value }, { v: graph.values[j].y, f: y_axis_label }]);
                }
            }
        }

        let find_time_range = TIME_DATA_RANGE.filter((time) => time.id === timeData);

        if (find_time_range.length > 0) {
            // set area time 
            data_chart[0].push("PEAK TIME");

            for (let i = 1; i < data_chart.length; i++) {
                let time_string = (data_chart[i][0].f).replace(":", ".");
                let time_row = parseFloat(time_string);
                // console.log(time_string + " ---> " + time_row);

                if ((time_row >= find_time_range[0].start) && (time_row <= find_time_range[0].stop)) {
                    data_chart[i].push({ v: max_h_value + 10, f: '' });
                } else {
                    data_chart[i].push({ v: null, f: 'no data' });
                }
            }
        }

        return { data: data_chart, tick: tick_chart, h_label: h_label, v_label: v_label, max_h_value: max_h_value }
    }

    async function getChart(sensor_id) {
        console.log("sensor_id : ", sensor_id);
        HttpRequest("/api/getsensorgraph", {
            "sensor_id": parseInt(sensor_id)
        }).then((data) => {
            console.log("HttpRequest /api/getsensorgraph:", data);
            if (data.result === "OK") {

                let data_speed = generateDataChart(data.data.graphs);
                let data_density = generateDataChart(data.data.graphs_density);
                let data_occupancy = generateDataChart(data.data.graphs_occupancy);
                let data_volume = generateDataChart(data.data.graphs_volume);

                let datachart = [
                    { title: "Speed", ...data_speed },
                    { title: "Density", ...data_density },
                    { title: "Occupancy", ...data_occupancy },
                    { title: "Volume", ...data_volume }
                ]
                console.log(datachart);

                let title_chart = data.data.date;
                SET_TITLE_CHART(title_chart);
                SET_DATA_CHART(datachart);
            }
        });
    }

    React.useEffect(() => {

        if (pin && pin.sensor_id) {
            getChart(pin.sensor_id);
        }

    }, [timeData]);

    return (
        (DATA_CHART) ? (
            <Fragment>
                <span style={{ marginLeft: 20 }}>Date : <b>{TITLE_CHART}</b></span>
                {
                    DATA_CHART.map((c, index) => {
                        // index
                        return <Chart
                            key={index}
                            chartType="ComboChart"
                            width="100%"
                            height="250px"
                            data={c.data}
                            options={{
                                // title: c.title,
                                title: c.v_label,
                                titleTextStyle: { color: "#000", fontSize: 15 },
                                interpolateNulls: "absolute",
                                curveType: "function",
                                legend: { position: "bottom", padding: 0 },
                                series: {
                                    0: { type: 'line' },
                                    1: { type: 'line' },
                                    2: { type: 'area', lineWidth: 0 }
                                },
                                hAxis: {
                                    title: c.h_label,
                                    // direction: -1,
                                    slantedText: true,
                                    slantedTextAngle: 270,
                                    showTextEvery: 4,
                                    ticks: c.tick,
                                    textStyle: { fontSize: 12 },
                                    gridlines: { color: "transparent" },
                                    titleTextStyle: { color: "#000", fontSize: 14 },
                                },
                                vAxis: {
                                    // title: c.v_label,
                                    viewWindow: {
                                        max: c.max_h_value + 10,
                                        min: 0
                                    }
                                },
                                chartArea: { left: 30, top: 40, right: 0, bottom: 80 },
                            }}
                        />
                    })
                }
            </Fragment>
        ) : (
            <div className="loading-popup">
                <Spinner />
                <div>กำลังโหลด</div>
            </div>
        )
    );
    // return null
}

export default LineChart;