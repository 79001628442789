
import './App.css';
import React, { Suspense, useState } from 'react';
import {
  // Navigate,
  useRoutes,
} from "react-router-dom";

import Spinner from "./component/spinner";
import { HttpRequest } from "./service/HttpRequest";
import { getRoutes } from "./routing/routes";

import {
  useGlobalContext,
  // GlobalContextProvider,
  setConfigApp,
  setEnum,
  setAuthentication
} from "./service/GlobalContext";


import { HintContent } from "./component/insane-hint-info";

const getConfig = () => {
  const Request = new Promise((resolve, reject) => {
    fetch(`${process.env.PUBLIC_URL}/config.json`)
      .then(res => res.json())
      .then(config => {
        // console.log(config);
        resolve(config)

      }).catch(err => { console.log(err, ' error') });
  });

  return Request;
}

async function getEnum() {

  return await HttpRequest("/api/getenum", {}).then((data) => {
    console.log("HttpRequest /api/getenum:", data);
    if (data.result === "OK") {
      return data.enum;
    } else {
      return null
    }
  });
}

async function checkSession() {
  return await HttpRequest("/api/checksession", {}).then((data) => {
    // console.log("HttpRequest /api/checksession:", data);

    if (data.result === "OK") {
      if (data.session.logout === false) {
        return {
          token: data.session.sessiontoken,
          user: data.user,
          permission: data.permission
        }
      } else {
        return {
          token: "",
          user: "",
          permission: data.permission
        }
      }
    } else {
      return {
        token: "",
        user: "",
        permission: data.permission
      }
    }

  });
}

function App() {

  const [globalState, setGlobalState] = useGlobalContext();
  const [Routes, setRoutes] = React.useState(getRoutes());

  // const routes = getRoutes();
  const router = useRoutes(Routes);

  React.useEffect(() => {
    let isMounted = true;

    async function IntitialApp() {
      try {
        
        let config_app = await getConfig();
        if (isMounted) setConfigApp(setGlobalState, config_app);

        let enum_data = await getEnum();
        if (isMounted) setEnum(setGlobalState, enum_data);

        let sesssion_data = await checkSession();
        if (isMounted) setAuthentication(setGlobalState, sesssion_data);

      } catch (error) {
        console.error("Error during initialization:", error);
      }
    }

    IntitialApp();

    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    let isMounted = true;
  
    let routes = undefined;
    if (globalState.auth) {
      routes = getRoutes(globalState.auth);
    } else {
      routes = getRoutes(null);
    }
  
    if (isMounted) {
      setRoutes(routes);
    }
  
    return () => {
      isMounted = false; // ตั้งค่าว่า component ถูก unmount แล้ว
    };
  }, [globalState.auth]);

  return (
    <Suspense fallback={
      <div className="main-content" style={{ alignItems: "center", justifyContent: "center" }}>
        <Spinner></Spinner>
        <div style={{ color: "#000" }}>กำลังโหลด</div>
      </div>
    }>
      <div className="main-contrainer">
        <HintContent />
        {router}
      </div>
    </Suspense>
  );
}

export default App;