import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "../layout/main-layout";

import LOGIN_PAGE from "../page/login";
import DASHBOARD_PAGE from "../page/dashboard";
import TRAFFIC_PAGE from "../page/traffic";

import SAFETY_PAGE from "../page/safety";
import SAFETY_ILLEGATION_PAGE from "../page/safety-illegation";
import SAFETY_ACCIDENT_PAGE from "../page/safety-accident";

import LOGISTICS_PAGE from "../page/logistic";
import REPORT_PAGE from "../page/report";
import USERS_PAGE from "../page/users";

const first_page = "traffic";

const routeList = [

    { path: "traffic", name: "traffic", displayName: "สภาพการจราจร", element: <TRAFFIC_PAGE />, enable: true },
    { path: "logistics", name: "logistics", displayName: "การขนส่งสินค้า", element: <LOGISTICS_PAGE />, enable: true },

    { path: "safety-accident", name: "safety-accident", displayName: "จุดอันตราย/ช่วงถนนอันตราย", element: <SAFETY_ACCIDENT_PAGE />, enable: true },
    { path: "safety-illegation", name: "safety-illegation", displayName: "การตรวจจับการกระทำผิด", element: <SAFETY_ILLEGATION_PAGE />, enable: true },


    // { path: "dashboard", name: "dashboard", displayName: "DASHBOARD", element: <DASHBOARD_PAGE />, enable: true },
    // { path: "safety", name: "safety", displayName: "SAFETY", element: <SAFETY_PAGE />, enable: true },
    // { path: "report", name: "report", displayName: "REPORT", element: <REPORT_PAGE />, enable: true },
    { path: "users", name: "users", displayName: "จัดการผู้ใช้งาน", element: <USERS_PAGE />, enable: true },

]

export const unauthorizedRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            { index: true, element: <Navigate to={first_page} />, enable: false },
            // { path: "dashboard", name: "dashboard", displayName: "DASHBOARD", element: <DASHBOARD_PAGE />, enable: true },
            { path: "dashboard", name: "dashboard", displayName: "EEC Dashboard", element: <DASHBOARD_PAGE />, enable: true },
            { path: "traffic", displayName: "TRAFFIC", index: true, element: <TRAFFIC_PAGE />, enable: true },
            { path: "login", displayName: "LOGIN", element: <LOGIN_PAGE />, enable: false }
        ]
    },
    {
        path: "*",
        element: <Navigate to={first_page} />
    }
];

export const getRoutes = (auth) => {

    let authorizedRoutes = [
        {
            path: "/",
            element: <Layout />,
            children: [
                { index: true, element: <Navigate to={first_page} />, enable: false },
                { path: "login", displayName: "LOGIN", element: <LOGIN_PAGE />, enable: false }
            ]
        },
    ]

    if (auth && auth.permission) {
        // console.log(auth.permission);

        for (let i = 0; i < routeList.length; i++) {
            const route = routeList[i];

            const splitPath = route.path.split("-");
            // console.log("splitPath : ", splitPath);

            if (auth.permission[splitPath[0]]) {
                authorizedRoutes[0].children.push(routeList[i]);
            }
        }

        return authorizedRoutes;
    } else {
        return unauthorizedRoutes;
    }
};
