import "./styles.css";
import React, { Fragment } from "react";
import { Chart } from "react-google-charts";

import { HttpRequest, HttpRequestForCSV } from "../../service/HttpRequest";

import Spinner from "../../component/spinner";
import OpenStreetMap from "../../component/insane-openstreet-map";

import InsaneCollapseContent from "../../component/insane-collapse-content";
import InsaneCollapseContentBottom from "../../component/insane-collapse-content-bottom";

import InsaneInputSearch from "../../component/insane-input-search";
import InsaneDatePicker from "../../component/insane-date-picker";

// import InsaneRatioBar from "../../component/insane-ratio-bar";

// new import form trafic page
import iconPinPort from "../../assets/icons/transport - port.png";
import iconPinAirport from "../../assets/icons/transport - airport.png";
import iconPinBus from "../../assets/icons/transport - bus.png";
import iconPinIndustrial from "../../assets/icons/transport - industrial.png";
import iconPinTrain from "../../assets/icons/transport - train.png";

import iconSetting from "../../assets/icons/settings_40dp_FFF.png";
import iconMore from "../../assets/icons/more_vert_40dp_FFF.png";

import WKX from "wkx";

import { useGlobalContext } from "../../service/GlobalContext";

function App() {

  const [globalState, setGlobalState] = useGlobalContext();

  const [loading, setLoading] = React.useState(false);

  const [search_option_od, set_search_option_od] = React.useState({
    date: "",
    time: "ALLDAY",
    province: (localStorage.getItem("search_province") || ""),
    vehicle_type: "truck",
    location_start: "",
    location_stop: "",
    od_mode: 0
  });

  const [search_option_volume, set_search_option_volume] = React.useState({
    date: "",
    time: "ALLDAY",
    province: (localStorage.getItem("search_province") || ""),
    month: "1M",
    dayofweek: "ALLDAY"
  });

  const [STATE_DISPLAY_MAP, SET_STATE_DISPLAY_MAP] = React.useState("od"); // od, volume

  const [fitBounds_MAP_ref, set_fitBounds_MAP_ref] = React.useState(null);

  const [legendLabel, setLegendLabel] = React.useState([]);
  const [legendVolumeLabel, setLegendVolumeLabel] = React.useState([]);
  const [legendLabelOdMode, setLegendLabelOdMode] = React.useState([]);

  const [TRAFFIC_MAP, SET_TRAFFIC_MAP] = React.useState([]);
  const [PIN_MAP, SET_PIN_MAP] = React.useState([]);
  const [POLYGON_MAP, SET_POLYGON_MAP] = React.useState([]);
  const [POLYLINE_MAP, SET_POLYLINE_MAP] = React.useState([]);
  const [CIRCLE_MAP, SET_CIRCLE_MAP] = React.useState([]);

  const [ENUM, SET_ENUM] = React.useState(null);
  const [PEAKOPTION, SET_PEAKOPTION] = React.useState([]);
  const [PROVINCES, SET_PROVINCES] = React.useState([]);
  const [VEHICLE_TYPE, SET_VEHICLE_TYPE] = React.useState([]);
  const [PERIODOPTION, SET_PERIODOPTION] = React.useState([]);
  const [DAYOFWEEKOPTION, SET_DAYOFWEEKOPTION] = React.useState([]);

  const [DATATABLE, SET_DATATABLE] = React.useState([]);

  const [state_filter, set_state_filter] = React.useState(false);
  const [state_detail, set_state_detail] = React.useState(false);

  const [mapSettingDefault, setMapSettingDefault] = React.useState({
    center: [13.762201991756932, 100.50106010074637],
    zoom: 10,
    raduis_m: 1000
  });

  const [DATA_TRAFFIC, SET_DATA_TRAFFIC] = React.useState(null);
  const [DATA_MAP_LINK, SET_DATA_MAP_LINK] = React.useState(null);

  const [DATA_PLACE, SET_DATA_PLACE] = React.useState(null);
  const [DATA_PLACE_CIRCLE, SET_DATA_PLACE_CIRCLE] = React.useState(null);
  const [DATA_OD_LINK, SET_DATA_OD_LINK] = React.useState(null);

  const [DATA_OD, SET_DATA_OD] = React.useState(null);

  const [DATA_CHART_BAR, SET_DATA_CHART_BAR] = React.useState(null);
  const [DATA_CHART_PIE, SET_DATA_CHART_PIE] = React.useState(null);

  const [DISPLAY_MAP_INDUSTRIAL, SET_DISPLAY_MAP_INDUSTRIAL] = React.useState(false);
  const [DISPLAY_MAP_PORT, set_DISPLAY_MAP_PORT] = React.useState(false);
  const [DISPLAY_MAP_AIRPORT, SET_DISPLAY_MAP_AIRPORT] = React.useState(false);
  const [DISPLAY_MAP_BUS, SET_DISPLAY_MAP_BUS] = React.useState(false);
  const [DISPLAY_MAP_TRAIN, SET_DISPLAY_MAP_TRAIN] = React.useState(false);

  const [DATA_MAP_INDUSTRIAL, SET_DATA_MAP_INDUSTRIAL] = React.useState([]);
  const [DATA_MAP_PORT, SET_DATA_MAP_PORT] = React.useState([]);
  const [DATA_MAP_BUS, SET_DATA_MAP_BUS] = React.useState([]);
  const [DATA_MAP_AIRPORT, SET_DATA_MAP_AIRPORT] = React.useState([]);
  const [DATA_MAP_TRAIN, SET_DATA_MAP_TRAIN] = React.useState(false);

  const [SELECTED_OD, SET_SELECTED_OD] = React.useState(null);

  function downloadCSV(csvContent, fileName) {
    // console.log(csvContent);
    let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  async function getPlaceLocation() {
    await HttpRequest("/api/getplace",
      {
        "search": "",
        "index:": 0,
        "count": 0,
        "province_id": search_option_od.province
      }
    ).then((data) => {

      console.log("HttpRequest getplace (getPlaceLocation) :", data); ///
      if (data.result === "OK") {

        let places = data.places;
        let industrialestate = [];
        let airport = [];
        let port = [];
        let bus = [];
        let train = [];

        for (let i = 0; i < places.length; i++) {
          const place = places[i];
          switch (place.place_type) {
            case "industrialestate":
              industrialestate.push(place);
              break;
            case "airport":
              airport.push(place);
              break;
            case "port":
              port.push(place);
              break;
            case "bus":
              bus.push(place);
              break;
            case "train":
              train.push(place);
              break;

            default:
              break;
          }
        }

        SET_DATA_MAP_INDUSTRIAL(industrialestate);
        SET_DATA_MAP_AIRPORT(airport);
        SET_DATA_MAP_PORT(port);
        SET_DATA_MAP_BUS(bus);
        SET_DATA_MAP_TRAIN(train);
        SET_DATA_PLACE(places);

      } else {

      }
    });
  }


  async function getPlace() {

    await HttpRequest("/api/getplace",
      {
        "search": "",
        "taz": true,
        "index:": 0,
        "count": 0,
        "province_id": search_option_od.province,
        "wkb": true
      }
    ).then((data) => {
      console.log("HttpRequest getplace :", data);
      if (data.result === "OK") {
        let places = data.places;

        for (let i = 0; i < places.length; i++) {
          places[i].pin_type = "places";
          let decode = Buffer.from(places[i].wkb, 'base64');
          places[i].geojson = WKX.Geometry.parseTwkb(decode).toGeoJSON();
        }

        let polygons = places.filter((p) => p.geojson.type !== "Point");

        let bus_polygon = [...DATA_MAP_BUS];
        for (let i = 0; i < bus_polygon.length; i++) {
          bus_polygon[i].pin_type = "places";
        }

        // console.log([...polygons, ...bus_polygon]);

        SET_POLYGON_MAP([...polygons, ...bus_polygon]);

        // SET_POLYGON_MAP(places);
        SET_CIRCLE_MAP(places);
        SET_DATA_PLACE_CIRCLE(places);

      } else {

      }
    });
  }

  async function getOd() {
    // console.log(search_time);

    await HttpRequest("/api/getOd",
      {
        "o_place_id": search_option_od.location_start,
        "d_place_id": search_option_od.location_stop,
        "date": search_option_od.date,
        "peak_id": search_option_od.time,
        "province_id": search_option_od.province,
        "taz": true,
        "index": 0,
        "count": 0,
        "vehicle_category": search_option_od.vehicle_type,
        "od_mode": search_option_od.od_mode
      }
    ).then((data) => {
      console.log("HttpRequest getOd :", data);
      if (data.result === "OK") {
        let places = data.places;
        let condition = data.condition_od_volume;

        let totalods = data.totalods;

        for (let i = 0; i < places.length; i++) {

          places[i].color = "#999";
          let status = ENUM.condition_od_mode.filter((legend) => places[i].mode_status === legend.status);
          if (status.length > 0) {
            places[i].color = status[0].color;
          }

          places[i].size = 5;
          let volume = condition.filter((legend) => places[i].volume_status === legend.status);
          if (volume.length > 0) {
            places[i].size = parseInt(volume[0].size);
          }

          places[i].geojson = {
            coordinates: [
              [places[i].o_lat, places[i].o_lon],
              [places[i].d_lat, places[i].d_lon]
            ]
          }

          places[i].type_polyline = "od";
        }


        let condition_mode = [...ENUM.condition_od_mode];

        for (let i = 0; i < condition_mode.length; i++) {
          let match_mode_status = totalods.filter((status) => status.mode_status === ENUM.condition_od_mode[i].status);
          if (match_mode_status.length > 0) {
            ENUM.condition_od_mode[i].count = match_mode_status[0].total;
          } else {
            ENUM.condition_od_mode[i].count = ""
          }
        }

        setLegendLabelOdMode(condition_mode);

        setLegendLabel(condition);

        SET_DATATABLE(places);
        SET_DATA_OD_LINK(places);

      } else {

      }
    });

  }

  async function downloadOD() {
    // setLoading(true);
    await HttpRequestForCSV("/api/downloadod ",
      {
        "o_place_id": search_option_od.location_start,
        "d_place_id": search_option_od.location_stop,
        "date": search_option_od.date,
        "peak_id": search_option_od.time,
        "taz": true,
        "index": 0,
        "count": 0,
        "vehicle_category": search_option_od.vehicle_type
      }
    ).then((data) => {
      // console.log("HttpRequest downloadod :", data);
      let fileName = ("OD_" + search_option_od.date + "_" + search_option_od.time);
      downloadCSV(data, fileName);
    });
  }

  function getOdLink(o_place_id, d_place_id) {
    setLoading(true);
    SET_POLYLINE_MAP([]);

    HttpRequest("/api/getodlink",
      {
        "o_place_id": o_place_id,
        "d_place_id": d_place_id,
        "date": search_option_od.date,
        "peak_id": search_option_od.time,
        "taz": true,
        "index:": null,
        "count": null,
        "vehicle_category": search_option_od.vehicle_type
      }
    ).then((data) => {
      console.log("HttpRequest getOdLink :", data);
      if (data.result === "OK") {

        let polyline = data.odlink.links;

        let arr_bounds = [];

        try {
          for (let i = 0; i < polyline.length; i++) {

            polyline[i].color = "#999";
            let status = ENUM.condition_traffic.filter((legend) => polyline[i].status === legend.status);

            if (status.length > 0) {
              polyline[i].color = status[0].color;
            }

            for (let j = 0; j < polyline[i].geojson.coordinates.length; j++) {
              polyline[i].geojson.coordinates[j].reverse();
            }

            polyline[i].type_polyline = "traffic";

            if (polyline[i].geojson.coordinates.length > 0) {
              arr_bounds = [...arr_bounds, ...polyline[i].geojson.coordinates];
            }
          }

          // console.log(arr_bounds);

          SET_POLYLINE_MAP(polyline);

          if (polyline.length > 0) {
            set_fitBounds_MAP_ref(arr_bounds);
          }
        } catch (error) {
          console.log(error);
        }

        setLoading(false);
      } else {

        setLoading(false);
      }
    });
  }

  async function getTraffic() {
    await HttpRequest("/api/getlink", {
      center_lat: 13.28772312454414,
      center_lon: 101.32467877424523,
      raduis_m: 1000,
      date: search_option_volume.date,
      peak_id: search_option_volume.time,
      province_id: search_option_volume.province, // location province on map (จาก select)
      period_id: search_option_volume.month,
      dayofweek_id: search_option_volume.dayofweek,
      vehicle_category: "truck",
      index: 0,
      count: 0,
      wkb: true,
    }).then((data) => {
      console.log("HttpRequest getTraffic :", data);
      if (data.result === "OK") {
        for (let i = 0; i < data.links.length; i++) {
          let link = data.links[i];
          let decode = Buffer.from(link.wkb, "base64");
          link.geojson = WKX.Geometry.parseTwkb(decode).toGeoJSON();
        }
        SET_DATA_TRAFFIC(data);
        SET_DATA_MAP_LINK(data.links);
        setLegendVolumeLabel(data.condition_volume);
      }
    });
  }

  async function getDashboard() {
    // setLoadingDataDashboard(true);

    await HttpRequest("/api/getdashboard", {
      date: search_option_volume.date,
      peak_id: search_option_volume.time,
      province_id: search_option_volume.province,
      dayofweek_id: search_option_volume.dayofweek,
    }).then((data) => {
      console.log("HttpRequest getdashboard  :", data);

      if (data.result === "OK") {
        // setLoadingDataDashboard(false);
        // SET_DATA_ZONE(data.zones); // error?
        SET_DATA_OD(data.od);
        // SET_DATA_ZONE(updatedZones);
      } else {
        // setLoadingDataDashboard(false);
      }
    });
  }

  function onClearOD() {

    set_search_option_od(
      {
        ...search_option_od,
        location_start: "",
        location_stop: ""
      })

    set_fitBounds_MAP_ref([]);
    SET_SELECTED_OD(null);

    getOd();
  }

  function formatedate(date = new Date()) {
    let d = new Date(date);
    let dd = d.getDate();
    let mm = d.getMonth() + 1;
    let yy = d.getFullYear();

    function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

    return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd)
  }

  // function getCurrentHour() {
  //   let d = new Date();
  //   return d.getHours();
  // }

  function getUnitTimeSelected() {
    let result = PEAKOPTION.filter((peak) => peak.id === search_option_od.time);
    if (result.length > 0) {
      return result[0].unit
    } else {
      return ""
    }
  }

  // function getDisplayFilter(main_key, display_key, find_key, find_value) {
  //   // console.log(ENUM);
  //   if (ENUM) {
  //     let arr_filter = ENUM[main_key];
  //     if (arr_filter) {
  //       let result = arr_filter.filter((e) => e[find_key] === find_value);
  //       if (result.length > 0) {
  //         return result[0][display_key];
  //       } else {
  //         return "";
  //       }
  //     } else {
  //       return "";
  //     }
  //   }
  // }

  React.useEffect(() => {
    const controller = new AbortController();

    let current_date = formatedate();
    // console.log(current_date);
    set_search_option_od({ ...search_option_od, date: current_date });
    set_search_option_volume({ ...search_option_volume, date: current_date });

    return () => {
      controller.abort();
    };
  }, []);

  React.useEffect(() => {
    const controller = new AbortController();

    if (globalState.enum) {

      let data_enum = { ...globalState.enum };
      SET_ENUM(data_enum);

      let new_state_option_map = {
        ...mapSettingDefault,
        center: [data_enum.default.center_lat, data_enum.default.canter_lon],
        raduis_m: data_enum.default.raduis_m
      }

      // setLegendLabel([...data_enum.condition_od_speed]);
      setLegendLabel(data_enum.condition_od_volume);
      setLegendVolumeLabel(data_enum.condition_volume);

      for (let i = 0; i < data_enum.condition_od_mode.length; i++) {
        data_enum.condition_od_mode[i].checked = true;
      }

      setLegendLabelOdMode([...data_enum.condition_od_mode]);

      setMapSettingDefault(new_state_option_map);
      SET_PEAKOPTION(data_enum.peak_logistics);
      SET_PROVINCES(data_enum.filter_province);
      SET_VEHICLE_TYPE(data_enum.vehicle_category);

      SET_PERIODOPTION(data_enum.period_dashboard);
      SET_DAYOFWEEKOPTION(data_enum.dayofweek);

      if (data_enum.filter_province.length > 0) {
        set_search_option_od({ ...search_option_od, province: data_enum.filter_province[0].id });
      }

    }

    return () => {
      controller.abort();
    };
  }, [globalState.enum]);


  React.useEffect(() => {
    const controller = new AbortController();

    SET_PIN_MAP([]);

    async function get() {
      await getPlaceLocation();
    }

    if (ENUM) {
      get();
    }

    return () => {
      controller.abort();
    };
  }, [ENUM]);

  React.useEffect(() => {
    const controller = new AbortController();

    // console.log(DATA_PLACE);
    if (DATA_PLACE) {
      setLoading(true);

      SET_POLYGON_MAP([]);
      SET_POLYLINE_MAP([]);
      SET_CIRCLE_MAP([]);

      async function get() {
        await getPlace();
        await getOd();
        await getTraffic();
        // await getDashboard();

        setLoading(false);
      }

      // console.log(search_option_od);

      if (ENUM && search_option_od.date && (search_option_od.date !== "")) {
        get();
      } else {

        let current_date = formatedate();
        // console.log(current_date);
        set_search_option_od({ ...search_option_od, date: current_date });
        set_search_option_volume({ ...search_option_volume, date: current_date });

      }
    }

    return () => {
      controller.abort();
    };
  }, [search_option_od, search_option_volume, DATA_PLACE]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_PLACE) {
      let pins = [];

      if (DISPLAY_MAP_INDUSTRIAL && DATA_MAP_INDUSTRIAL) {
        // console.log("DISPLAY_MAP_INDUSTRIAL");
        let pins_industrial = [...DATA_MAP_INDUSTRIAL];

        for (let i = 0; i < pins_industrial.length; i++) {
          pins_industrial[i].pin_type = "industrialestate";
        }

        pins = [...pins, ...pins_industrial];
      }

      if (DISPLAY_MAP_PORT && DATA_MAP_PORT) {
        // console.log("DISPLAY_MAP_PORT");
        let pins_port = [...DATA_MAP_PORT];
        for (let i = 0; i < pins_port.length; i++) {
          pins_port[i].pin_type = "port";
        }

        pins = [...pins, ...pins_port];
      }

      if (DISPLAY_MAP_AIRPORT && DATA_MAP_AIRPORT) {
        // console.log("DISPLAY_MAP_AIRPORT");
        let pins_airport = [...DATA_MAP_AIRPORT];
        for (let i = 0; i < pins_airport.length; i++) {
          pins_airport[i].pin_type = "airport";
        }

        pins = [...pins, ...pins_airport];
      }

      if (DISPLAY_MAP_BUS && DATA_MAP_BUS) {
        // console.log("DISPLAY_MAP_BUS");
        let pins_bus = [...DATA_MAP_BUS];
        for (let i = 0; i < pins_bus.length; i++) {
          pins_bus[i].pin_type = "bus";
        }

        pins = [...pins, ...pins_bus];
      }


      if (DISPLAY_MAP_TRAIN && DATA_MAP_TRAIN) {
        // console.log("DISPLAY_MAP_TRAIN");
        let pins_train = [...DATA_MAP_TRAIN];
        for (let i = 0; i < pins_train.length; i++) {
          pins_train[i].pin_type = "train";
        }
        pins = [...pins, ...pins_train];
      }

      SET_PIN_MAP(pins);
    }

    return () => {
      controller.abort();
    };
  }, [
    DISPLAY_MAP_INDUSTRIAL, DATA_MAP_INDUSTRIAL,
    DISPLAY_MAP_PORT, DATA_MAP_PORT,
    DISPLAY_MAP_AIRPORT, DATA_MAP_AIRPORT,
    DISPLAY_MAP_BUS, DATA_MAP_BUS,
    DISPLAY_MAP_TRAIN, DATA_MAP_TRAIN
  ]);


  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_OD_LINK) {
      if (STATE_DISPLAY_MAP === "od") {

        SET_POLYLINE_MAP([]);

        SET_CIRCLE_MAP([]);

        let places = [...DATA_OD_LINK];
        let places_polyline = [];

        for (let j = 0; j < legendLabelOdMode.length; j++) {
          if (legendLabelOdMode[j].checked) {

            let match_place_by_mode_status = places.filter((place) => place.mode_status === legendLabelOdMode[j].status);
            let match_place_by_available_od = match_place_by_mode_status.filter((place) => ((place.o_lat && place.o_lon) && (place.d_lat && place.d_lon)));

            places_polyline = [...places_polyline, ...match_place_by_available_od]
          }
        }

        SET_POLYLINE_MAP(places_polyline);

        setTimeout(() => {
          SET_CIRCLE_MAP(DATA_PLACE_CIRCLE);
        }, 500);

      } else {
        SET_POLYLINE_MAP([]);
      }
    }

    return () => {
      controller.abort();
    };
  }, [DATA_OD_LINK, STATE_DISPLAY_MAP, legendLabelOdMode]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_TRAFFIC) {
      if (DATA_MAP_LINK) {
        if (STATE_DISPLAY_MAP === "volume") {
          let polyline = [...DATA_TRAFFIC.links];

          for (let i = 0; i < polyline.length; i++) {
            polyline[i].color = "#999";
            let status = ENUM.condition_safety.filter(
              (legend) => polyline[i].status === legend.status
            );
            let weight = legendVolumeLabel.filter(
              (legend) => polyline[i].status_volume === legend.status
            );
            // legendLabel

            polyline[i].type = "traffic";

            if (status.length > 0) {
              polyline[i].color = status[0].color;
              polyline[i].color = "magenta"; //  Color in line map
            }

            if (weight.length > 0) {
              polyline[i].size = weight[0].size.replace("px", "");
            }
          }

          SET_TRAFFIC_MAP(polyline);
        } else {
          SET_TRAFFIC_MAP([]);
        }
      }
    }
    return () => {
      controller.abort();
    };
  }, [DATA_MAP_LINK, STATE_DISPLAY_MAP]);

  React.useEffect(() => {
    const controller = new AbortController();

    if (DATA_OD) {
      //let data_bar_chart = [["date", "value", { 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } }]];
      let data_bar_chart = [
        [
          "date",
          "value",
          { type: "string", role: "tooltip", p: { html: true } },
          { role: "style" },
        ],
      ];

      for (let i = 0; i < DATA_OD.od_dates.length; i++) {
        const row = DATA_OD.od_dates[i];

        // new code
        const date = new Date(row.date); // แปลงวันที่จากข้อมูล
        const dayOfWeek = date.getDay(); // ค่าที่แสดงวันในสัปดาห์ (0 = อาทิตย์, 6 = เสาร์)

        // ตรวจสอบว่าตรงกับวันเสาร์ (6) หรืออาทิตย์ (0) เพื่อกำหนดสีส้ม
        let barColor = dayOfWeek === 0 || dayOfWeek === 6 ? "orange" : "white";

        // console.log(getDate(row.date));
        data_bar_chart.push([
          // { v: getDate(row.date), f: row.date },
          // { v: (i+1), f: row.date },
          // { v: "", f: row.date },
          // getDate(row.date),
          row.x_label,
          parseInt(row.total_vehicle),
          row.y_label,
          barColor, // colors
        ]);
      }
      // console.log('This is data_bar_chart', data_bar_chart);
      SET_DATA_CHART_BAR(data_bar_chart);

      let data_pie_chart = [
        ["toppic", "value"],
        ["เดินทางภายในพื้นที่", parseInt(DATA_OD.total_internal_vehicle)],
        ["เดินทางเข้าออกพื้นที่่", parseInt(DATA_OD.total_cross_vehicle)],
        ["เดินทางผ่านพื้นที่", parseInt(DATA_OD.total_external_vehicle)],
      ];

      // console.log(data_pie_chart);
      SET_DATA_CHART_PIE(data_pie_chart);
    }

    return () => {
      controller.abort();
    };
  }, [DATA_OD]);

  return (
    <>
      {loading ? (
        <div
          className="main-content"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Spinner></Spinner>
          <div style={{ color: "#000" }}>กำลังโหลด</div>
        </div>
      ) : (
        <div className="main-content">
          <div className="page-content">

            <InsaneCollapseContent title="Map option" type="filter" color="#005baf" style={{ zIndex: 20 }} state={state_filter} stateChange={set_state_filter}>
              <div className="filter-content">


                <div className="checkbox-display-container">
                  <div className="head-label">แสดงสภาพจราจร</div>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox"
                      checked={STATE_DISPLAY_MAP === "od"}
                      onChange={(event) => SET_STATE_DISPLAY_MAP("od")} />
                    <span className="checkmark"></span>
                    <div className="label">จุดต้นทาง-จุดปลายทาง</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox"
                      checked={STATE_DISPLAY_MAP === "volume"}
                      onChange={(event) => SET_STATE_DISPLAY_MAP("volume")} />
                    <span className="checkmark"></span>
                    <div className="label">ปริมาณรถบรรทุกตั้งแต่ 10 ล้อขึ้นไป บนถนน</div>
                  </label>

                </div>

                <div className="checkbox-display-container">
                  <div className="head-label">แสดงโครงสร้างพื้นฐานคมนาคม</div>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_PORT} onChange={(event) => set_DISPLAY_MAP_PORT(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinPort} />
                    <div className="label">ท่าเรือ</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_INDUSTRIAL} onChange={(event) => SET_DISPLAY_MAP_INDUSTRIAL(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinIndustrial} />
                    <div className="label">นิคมอุตสาหกรรม</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_AIRPORT} onChange={(event) => SET_DISPLAY_MAP_AIRPORT(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinAirport} />
                    <div className="label">ท่าอากาศยาน</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_BUS} onChange={(event) => SET_DISPLAY_MAP_BUS(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinBus} />
                    <div className="label">สถานีขนส่งผู้โดยสาร</div>
                  </label>

                  <label className="custom-checkmark checkbox">
                    <input type="checkbox" checked={DISPLAY_MAP_TRAIN} onChange={(event) => SET_DISPLAY_MAP_TRAIN(event.target.checked)} />
                    <span className="checkmark"></span>
                    <img className="icon" src={iconPinTrain} />
                    <div className="label">สถานีรถไฟ</div>
                  </label>
                </div>

                {
                  (STATE_DISPLAY_MAP === "od") ? (
                    <Fragment>
                      <div className="checkbox-display-container">
                        <div className="head-label">ประเภทยานพาหนะ</div>

                        {VEHICLE_TYPE.map((option, index) => {
                          return (
                            <label className="custom-checkmark checkbox" key={index}>
                              <input type="checkbox"
                                checked={search_option_od.vehicle_type === option.id}
                                onChange={(event) => set_search_option_od({ ...search_option_od, vehicle_type: option.id })} />
                              <span className="checkmark"></span>
                              <div className="label">{option.title}</div>
                            </label>
                          );
                        })}

                      </div>
                      {
                        (legendLabelOdMode.length > 0) ? (
                          <div className="checkbox-display-container" style={{ marginBottom: 10 }}>
                            {/* <div className="head-label">ความเร็วเฉลี่ย</div> */}
                            <div className="head-label">ทิศทางการเดินทาง</div>
                            {
                              legendLabelOdMode.map((legend, index) => {

                                return (
                                  <label className="custom-checkmark checkbox" key={index}>
                                    <input type="checkbox" checked={legend.checked}

                                      onChange={(event) => {
                                        let new_state = [...legendLabelOdMode];
                                        new_state[index].checked = event.target.checked;
                                        setLegendLabelOdMode(new_state);
                                      }} />

                                    <span className="checkmark"></span>
                                    <div className="color" style={{
                                      width: 20,
                                      borderRadius: 5,
                                      background: legend.color ? legend.color : "#444",
                                      minHeight: legend.size ? "unset" : 20,
                                      height: legend.size ? parseInt(legend.size) : 20
                                    }}></div>
                                    <div className="label">
                                      {legend.title} <br />
                                      {legend.count}
                                    </div>
                                  </label>
                                );
                              })
                            }
                          </div>
                        ) : (null)
                      }

                      <div style={{ flexGrow: 1 }}></div>

                      {
                        (legendLabel.length > 0) ? (
                          <div className="legend-label-container">
                            {/* <div className="head-label">ความเร็วเฉลี่ย</div> */}
                            <div className="head-label">จำนวนเที่ยววิ่ง/วัน</div>
                            {
                              legendLabel.map((label, index) => {
                                return (
                                  <div className="legend" key={index}>
                                    <div className="color" style={{
                                      background: label.color ? label.color : "#444",
                                      minHeight: label.size ? "unset" : 20,
                                      height: label.size ? parseInt(label.size) : 20
                                    }}></div>
                                    <div className="text">{label.title}</div>
                                  </div>
                                );
                              })
                            }
                          </div>
                        ) : (null)
                      }
                    </Fragment>
                  ) : (null)
                }

                {
                  (STATE_DISPLAY_MAP === "volume") ? (
                    <Fragment>

                      <div style={{ flexGrow: 1 }}></div>

                      <div className="legend-label-container">
                        <div className="head-label">จำนวนรถบรรทุก</div>
                        {legendVolumeLabel.map((list, index) => (
                          // <div key={index} className="legend" style={{ backgroundColor: list.color }}>{list.title}</div>
                          <div className="legend" key={index}>
                            <div
                              className="color"
                              style={{
                                background: list.color ? list.color : "magenta",
                                minHeight: list.size ? "unset" : 20,
                                height: list.size ? parseInt(list.size) : 20,
                              }}
                            ></div>
                            <div className="text">{list.title}</div>
                          </div>
                        ))}
                      </div>
                    </Fragment>
                  ) : (null)
                }

              </div>
            </InsaneCollapseContent>

            <InsaneCollapseContent title="DETAIL" type="detail" color="#34495E" style={{ zIndex: 19 }} state={state_detail} stateChange={set_state_detail}>

              <div className="detail-content">

                <div className="title" style={{ width: "100%", display: "flex" }}>
                  <h4 style={{ fontWeight: "bolder" }}>รายการ OD</h4>
                  <div style={{ flexGrow: 1 }}></div>
                  <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                    <button style={{ color: "#FFF", fontSize: 14, fontWeight: "bolder" }} onClick={() => onClearOD()}>ล้างข้อมูล</button>
                    <button style={{ color: "#FFF", fontSize: 14, fontWeight: "bolder" }} onClick={() => downloadOD()}>ดาวน์โหลด</button>
                  </div>
                </div>

                <div>
                  <InsaneInputSearch
                    api="/api/getplace"
                    param={{
                      "search": "",
                      "taz": true,
                      "index:": 0,
                      "count": 0
                    }}
                    type="text"
                    label="เลือกจุดต้นทาง"
                    attDisplay="name"
                    attValue="place_id"
                    value={search_option_od.location_start}
                    change={(event) => set_search_option_od({ ...search_option_od, location_start: event })}
                    placeholder="ค้นหา.."
                  ></InsaneInputSearch>

                  <InsaneInputSearch
                    api="/api/getplace"
                    param={{
                      "search": "",
                      "taz": true,
                      "index:": 0,
                      "count": 0
                    }}
                    type="text"
                    label="เลือกจุดปลายทาง"
                    attDisplay="name"
                    attValue="place_id"
                    value={search_option_od.location_stop}
                    change={(event) => set_search_option_od({ ...search_option_od, location_stop: event })}
                    placeholder="ค้นหา.."
                  ></InsaneInputSearch>

                  <button style={{ color: "#FFF", width: "100%" }} type="submit" onClick={() => getOd()}>ค้นหา</button>

                </div>
                <div className="content-table">

                  <div className="head-table" style={{ background: "#ccc" }}>
                    <div className="field">ต้นทาง</div>
                    <div className="field">ปลายทาง</div>
                    <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>จำนวนรถ<br />({getUnitTimeSelected()})</div>
                    <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>ระยะเวลาเดินทาง<br />(นาที)</div>
                  </div>

                  {DATATABLE.map((list, index) => {
                    return (
                      <div
                        key={index}
                        className="list-table"
                        style={{
                          height: "unset",
                          color: ((list === SELECTED_OD) ? "#FFF" : ""),
                          background: ((list === SELECTED_OD) ? "#7288b9" : "")
                        }}
                        onClick={() => {
                          getOdLink(list.o_place_id, list.d_place_id);
                          SET_SELECTED_OD(list);
                        }}>
                        <div className="field">{list.o_name}</div>
                        <div className="field">{list.d_name}</div>
                        <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>{parseFloat(list.total_vehicle).toFixed(0)}</div>
                        <div className="field" style={{ flexGrow: "unset", flexBasis: "unset", width: 60 }}>{parseFloat(list.avg_traveltime_m).toFixed(0)}</div>
                      </div>
                    );
                  })}
                </div>

              </div>
            </InsaneCollapseContent>

            <div className={"content " + (STATE_DISPLAY_MAP === "volume" ? "content-map-logistic" : "")}>

              {
                STATE_DISPLAY_MAP === "od" ? (
                  <div className="content-map-header">

                    <button className="bt-toggle" onClick={() => {
                      state_filter ? set_state_filter(false) : set_state_filter(true)
                    }}>
                      <img src={iconSetting} />
                      <label>Map option</label>
                    </button>

                    <button className="bt-toggle" onClick={() => {
                      state_detail ? set_state_detail(false) : set_state_detail(true)
                    }}>
                      <img src={iconMore} />
                      <label>Detail</label>
                    </button>

                    <div className="container-input">
                      <label>พื้นที่</label>

                      <select
                        className="form-control"
                        value={search_option_od.province || ""} // ถ้าไม่มีค่า ให้ใช้ค่าเริ่มต้นเป็น ""
                        onChange={(event) => set_search_option_od({ ...search_option_od, province: event.target.value })}
                      >
                        {PROVINCES.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="container-input">
                      <label>วันที่</label>
                      <input
                        type="date"
                        className="form-control input-date-mobile"
                        value={search_option_od.date}
                        onChange={(event) => set_search_option_od({ ...search_option_od, date: event.target.value })}
                      />

                      <InsaneDatePicker
                        type="date"
                        className="form-control input-date-web"
                        value={search_option_od.date}
                        change={(event) => set_search_option_od({ ...search_option_od, date: event })}
                      />

                    </div>

                    <div className="container-input">
                      <label>เวลา</label>

                      <select
                        className="form-control"
                        value={search_option_od.time}
                        onChange={(event) => set_search_option_od({ ...search_option_od, time: event.target.value })}
                      >
                        {PEAKOPTION.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.title}
                            </option>
                          );
                        })}
                      </select>

                    </div>

                    <div className="web" style={{ flexGrow: 1 }}></div>

                  </div>
                ) : (null)
              }

              {
                STATE_DISPLAY_MAP === "volume" ? (
                  <div className="content-map-header">

                    <button className="bt-toggle" onClick={() => {
                      state_filter ? set_state_filter(false) : set_state_filter(true)
                    }}>
                      <img src={iconSetting} />
                      <label>Map option</label>
                    </button>

                    <button className="bt-toggle" onClick={() => {
                      state_detail ? set_state_detail(false) : set_state_detail(true)
                    }}>
                      <img src={iconMore} />
                      <label>Detail</label>
                    </button>

                    <div className="container-input">
                      <label>พื้นที่</label>
                      <select
                        value={search_option_volume.province}
                        onChange={(event) => set_search_option_volume({ ...search_option_volume, province: event.target.value })}
                      >
                        {PROVINCES.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="container-input">
                      <label>เวลา</label>
                      <select
                        value={search_option_volume.time}
                        onChange={(event) => set_search_option_volume({ ...search_option_volume, time: event.target.value })}
                      >
                        {PEAKOPTION.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <span className="container-input">
                      <label>ช่วงเวลา</label>
                      <select
                        value={search_option_volume.month}
                        onChange={(event) => set_search_option_volume({ ...search_option_volume, month: event.target.value })}
                      >
                        {PERIODOPTION.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.title}
                            </option>
                          );
                        })}
                      </select>
                    </span>

                    <span className="container-input">
                      <label style={{ width: "200px" }}>ประเภทของวัน</label>
                      <select
                        value={search_option_volume.dayofweek}
                        onChange={(event) => set_search_option_volume({ ...search_option_volume, dayofweek: event.target.value })}
                      >
                        {DAYOFWEEKOPTION.map((option, index) => {
                          return (
                            <option key={index} value={option.id}>
                              {option.title}
                            </option>
                          );
                        })}
                      </select>
                    </span>

                    <div className="web" style={{ flexGrow: 1 }}></div>
                  </div>
                ) : (null)
              }

              <OpenStreetMap
                traffic={TRAFFIC_MAP}
                pinmap={PIN_MAP}
                polygon={POLYGON_MAP}
                polyline={POLYLINE_MAP}
                circle={CIRCLE_MAP}
                fitBoundsRef={fitBounds_MAP_ref}
                center={mapSettingDefault.center}
                zoom={mapSettingDefault.zoom}
              />

            </div>

            {/* {
              (STATE_DISPLAY_MAP === "volume") ? (
                <InsaneCollapseContentBottom
                  title="ข้อมูลภาพรวม"
                  color="#6d8494"
                  className="custom-collapse-content-bottom"
                  style={{ zIndex: 10 }}
                >
                  <div className="content-chart">

                    <div className="chart-title">
                      ปริมาณรถบรรทุกตั้งแต่ 10 ล้อขึ้นไป <br />
                      รวมเข้าออกและผ่านพื้นที่ทั้งหมด (คัน-เที่ยว/วัน)
                    </div>

                    <div className="chart" style={{ background: "#666" }}>
                      {DATA_CHART_BAR ? (
                        <Fragment>
                          <div className="web" style={{ width: "100%", height: "100%" }}>
                            <Chart
                              chartType="ColumnChart"
                              width="100%"
                              height="100%"
                              data={DATA_CHART_BAR} // กราฟแท่ง
                              options={{
                                titleTextStyle: {
                                  fontSize: 20,
                                  color: "#FFF",
                                  fontName: "mainFont",
                                },
                                backgroundColor: "transparent",
                                legend: { position: "none" },
                                hAxis: {
                                  alignment: "end",
                                  titleTextStyle: {
                                    color: "#FFF",
                                  },
                                  minValue: 0,
                                  textStyle: {
                                    fontSize: 10,
                                    color: "white",
                                    fontWeight: "bolder",
                                  },
                                },
                                vAxis: {
                                  textStyle: {
                                    color: "white",
                                    fontWeight: "bolder",
                                  },
                                },
                                chartArea: { left: 80, top: 20, right: 50, bottom: 60 },
                                tooltip: { isHtml: true },
                              }}
                            />
                          </div>

                          <div className="mobile" style={{height: "100%"}}>
                            <Chart
                              chartType="ColumnChart"
                              width="100%"
                              height="95%"
                              data={DATA_CHART_BAR}
                              options={{
                                titleTextStyle: {
                                  fontSize: 16,
                                  color: "#FFF",
                                  fontName: "mainFont",
                                },
                                backgroundColor: "transparent",
                                legend: { position: "none" },
                                hAxis: {
                                  // title: "วันที่",
                                  titleTextStyle: {
                                    color: "#FFF",
                                  },
                                  minValue: 0,
                                  textStyle: {
                                    fontSize: 10,
                                    color: "white",
                                    fontWeight: "bolder",
                                  },
                                },
                                vAxis: {
                                  textStyle: {
                                    color: "white",
                                    fontWeight: "bolder",
                                  },
                                },
                                chartArea: { left: 40, top: 40, right: 50, bottom: 40 },
                              }}
                            />
                          </div>
                        </Fragment>
                      ) : null}
                      <p className="hAxis-date">วันที่</p>

                    </div>

                    <div className="chart-title">
                      สัดส่วนการเดินทางของรถบรรทุกตั้งแต่ 10 ล้อ ขึ้นไป
                    </div>

                    <div className="chart" style={{ background: "#666" }}>
                      {DATA_CHART_PIE ? (
                        <Fragment>
                          <div className="web" style={{ width: "100%", height: "100%" }}>
                            <Chart
                              chartType="PieChart"
                              width="100%"
                              height="100%"
                              data={DATA_CHART_PIE}
                              options={{
                                title: "",
                                titleTextStyle: {
                                  fontSize: 20,
                                  color: "#000",
                                },
                                is3D: true,
                                slices: {
                                  0: { color: "#C00000", textStyle: {color: '#FFF'}},
                                  1: { color: "#0000FF", textStyle: {color: '#FFF'}},
                                  2: { color: "cyan", textStyle: {color: '#000'}}
                                },
                                backgroundColor: "transparent",
                                legend: {
                                  textStyle: {
                                    color: "#FFF",
                                    fontSize: 14,
                                    fontName: "mainFont",
                                    fontWeight: "bolder",
                                  },
                                  position: "right",
                                  alignment: "center",
                                },
                                hAxis: {
                                  minValue: 0,
                                  textStyle: {
                                    fontSize: 10,
                                    color: "#000",
                                  },
                                },
                                vAxis: {
                                  textStyle: {
                                    color: "#000",
                                  },
                                },
                                pieSliceTextStyle: {
                                  fontSize: 14,
                                  fontWeight: "bolder"
                                },
                                tooltip: {
                                  isHtml: true,
                                  trigger: "selection",
                                },
                                chartArea: { left: 0, top: 60, right: 0, bottom: 60 },
                              }}
                            />
                          </div>

                          <div
                            className="mobile"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <Chart
                              chartType="PieChart"
                              width="100%"
                              height="95%"
                              data={DATA_CHART_PIE}
                              options={{
                                title: "",
                                titleTextStyle: {
                                  fontSize: 20,
                                  color: "#FFF",
                                },
                                is3D: true,
                                slices: {
                                  0: { color: "#C00000", textStyle: {color: '#FFF'}},
                                  1: { color: "#0000FF", textStyle: {color: '#FFF'}},
                                  2: { color: "cyan", textStyle: {color: '#000'}}
                                },
                                backgroundColor: "transparent",
                                legend: {
                                  textStyle: {
                                    color: "#FFF",
                                    fontName: "mainFont",
                                    fontWeight: "bolder",
                                  },
                                  position: "bottom",
                                  alignment: "center",
                                },
                                hAxis: {
                                  minValue: 0,
                                  textStyle: {
                                    fontSize: 12,
                                    color: "#000",
                                  },
                                },
                                vAxis: {
                                  textStyle: {
                                    color: "#000",
                                  },
                                },
                                pieSliceTextStyle: {
                                  fontSize: 10,
                                  fontWeight: "bolder"
                                },
                                tooltip: {
                                  isHtml: true,
                                  trigger: "selection",
                                },
                                chartArea: { left: 0, top: 0, right: 0, bottom: 50 },
                              }}
                            />
                          </div>
                        </Fragment>
                      ) : null}
                    </div>

                  </div>
                </InsaneCollapseContentBottom>

              ) : (null)
            } */}

            <div className="content-mobile"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
