import "./styles.css";
import React from "react";

import closeIcon from "../../../assets/icons/close.png";

import Spinner from "../../../component/spinner";
import InsaneDatePicker from "../../../component/insane-date-picker";

import { HttpRequest, HttpRequestForCSV } from "../../../service/HttpRequest";
import { useGlobalContext } from "../../../service/GlobalContext";

function ModalReport({ sensor_id, onChangeShow }) {

    const [globalState, setGlobalState] = useGlobalContext();
    const [loadingReport, setLoadingReport] = React.useState(false);

    const [SENSER_ID, SET_SENSOR_ID] = React.useState(null);
    const [REPORT_ID, SET_REPORT_ID] = React.useState("SPEED");
    const [REPORT_NAME, SET_REPORT_NAME] = React.useState("");

    const [search_date, set_search_date] = React.useState("");
    const [search_enddate, set_search_enddate] = React.useState("");

    const [DATA_SENSOR, SET_DATA_SENSOR] = React.useState(null);
    const [DATA_REPORT, SET_DATA_REPORT] = React.useState(null);
    const [TITLE_REPORT, SET_TITLE_REPORT] = React.useState("");
    const [TYPE_LIST_REPORT, SET_TYPE_LIST_REPORT] = React.useState([]);

    const [CONDITION_REPORT, SET_CONDITION_REPORT] = React.useState(null);

    function toggleContent() {
        if (!SENSER_ID) {
            SET_SENSOR_ID(sensor_id);
        } else {
            SET_SENSOR_ID(null);
            onChangeShow(false);
            SET_TITLE_REPORT("");
        }
    }


    function formatedate(date = new Date()) {
        let d = new Date(date);
        let dd = d.getDate();
        let mm = d.getMonth() + 1;
        let yy = d.getFullYear();

        function zerobefore(n) { return (n > 9) ? (n) : ("0" + n) }

        return yy + "-" + zerobefore(mm) + "-" + zerobefore(dd)
    }

    function downloadCSV(csvContent, fileName) {
        // console.log(csvContent);
        let encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    function getSensorReport() {
        setLoadingReport(true);

        HttpRequest("/api/getsensorreport",
            {
                sensor_id: SENSER_ID,
                fromdate: search_date,
                todate: search_enddate,
                report_types: [REPORT_ID]
            }
        ).then((data) => {
            console.log("HttpRequest getsensorreport :", data);

            if (data.result === "OK") {
                SET_DATA_REPORT(data.data);
                SET_TITLE_REPORT(data.data.title);
            }

            setLoadingReport(false);
        });

    }


    async function downloadSensorReport() {
        // setLoading(true);
        await HttpRequestForCSV("/api/downloadsensorreport ",
            {
                sensor_id: SENSER_ID,
                fromdate: search_date,
                todate: search_enddate,
                report_types: [REPORT_ID]
            }
        ).then((data) => {
            // console.log("HttpRequest downloadsensorreport :", data);
            let fileName = ("Report_" + REPORT_ID + "_" + search_date + "_" + search_enddate);
            downloadCSV(data, fileName);
        });
    }

    function getColorStatus(status) {
        // console.log(status);
        if (CONDITION_REPORT) {
            let find_status = CONDITION_REPORT.filter((condition) => condition.status === status);
            if (find_status.length > 0) {
                return find_status[0].color;
            } else {
                return "#FFF"
            }
        } else {
            return "#FFF"
        }
    }

    function getColorWeekend(date_string) {
        var myDate = new Date(date_string);

        if (myDate.getDay() === 6) {
            // console.log(myDate.getDay())
            // return "#CFC3FF"
            return "#46e2fa"
        } else if (myDate.getDay() === 0) {
            // console.log(myDate.getDay())
            // return "#FFCAC3"
            return "#46e2fa"
        } else {
            return "#FFF"
        }
    }

    React.useEffect(() => {
        const controller = new AbortController();

        let current_date = new Date();

        let date_start = formatedate(current_date);
        let date_end = formatedate(current_date.setDate(current_date.getDate() + 1));

        set_search_date("2024-01-01");
        set_search_enddate(date_start);

        // getSensor();

        return () => {
            controller.abort();
        };
    }, []);


    React.useEffect(() => {
        const controller = new AbortController();

        console.log(sensor_id);

        if (sensor_id) {
            SET_SENSOR_ID(sensor_id);
        }

        return () => {
            controller.abort();
        };
    }, [sensor_id]);


    React.useEffect(() => {
        const controller = new AbortController();

        if (globalState.enum) {
            console.log(globalState.enum.condition_report);
            console.log(globalState.enum.report_type);
            SET_TYPE_LIST_REPORT(globalState.enum.report_type);
            SET_CONDITION_REPORT(globalState.enum.condition_report[REPORT_ID]);
        }

        return () => {
            controller.abort();
        };
    }, [globalState.enum]);


    React.useEffect(() => {
        const controller = new AbortController();

        if (globalState.enum && SENSER_ID) {
            getSensorReport();
        }

        return () => {
            controller.abort();
        };
    }, [
        SENSER_ID,
        REPORT_ID,
        search_date,
        search_enddate
    ]);


    React.useEffect(() => {
        const controller = new AbortController();

        if (globalState.enum) {
            SET_CONDITION_REPORT(globalState.enum.condition_report[REPORT_ID]);

            // console.log( globalState.enum.report_type)
            let find_report_name = globalState.enum.report_type.filter((type) => type.id === REPORT_ID);

            if (find_report_name.length > 0) {
                SET_REPORT_NAME(find_report_name[0].title);
            }
        }

        return () => {
            controller.abort();
        };
    }, [
        REPORT_ID
    ]);



    return (
        <div className={"modal-report-content " + (SENSER_ID ? "active" : "")} >
            <div className="content-box">
                <div className="header">
                    <div className="title">{SENSER_ID} - {TITLE_REPORT}</div>
                    <div className="bt-close" onClick={() => toggleContent()}><img src={closeIcon} /></div>
                </div>
                <div className="content">

                    {
                        loadingReport ? (
                            <div
                                className="main-content"
                                style={{ alignItems: "center", justifyContent: "center" }}
                            >
                                <Spinner></Spinner>
                                <div style={{ color: "#000" }}>กำลังโหลด</div>
                            </div>
                        ) : (

                            <>
                                <div className="display-control-contianer" style={{ display: "flex" }}>
                                    <div className="contianer" style={{ width: "100%" }}>
                                        {/* <div className="title title-report" style={{ background: "#96cdff" }}>{REPORT_NAME}</div> */}
                                        <select
                                            className="title title-report"
                                            style={{ background: "#96cdff", fontSize: "unset", height: "unset" }}
                                            defaultValue={REPORT_ID}
                                            onChange={(e) => {
                                                SET_REPORT_ID(e.target.value);
                                            }}>
                                            {
                                                TYPE_LIST_REPORT.map((report_type, i) => {
                                                    return (
                                                        <option key={i} value={report_type.id}> {report_type.title} </option>
                                                    )
                                                })
                                            }
                                        </select>

                                        <div className="title title-report" style={{ background: "lightblue" }}>{TITLE_REPORT}</div>
                                        <input
                                            type="date"
                                            className="input-date-mobile"
                                            value={search_date}
                                            onChange={(event) => set_search_date(event.target.value)}
                                        />
                                        <InsaneDatePicker
                                            type="date"
                                            className="input-date-web"
                                            value={search_date}
                                            change={set_search_date}
                                        />
                                        <span>-</span>
                                        <input
                                            type="date"
                                            className="input-date-mobile"
                                            value={search_enddate}
                                            onChange={(event) => set_search_enddate(event.target.value)}
                                        />
                                        <InsaneDatePicker
                                            type="date"
                                            className="input-date-web"
                                            value={search_enddate}
                                            change={set_search_enddate}
                                        />
                                        <button className="button bt-search" onClick={() => { getSensorReport() }}>ค้นหา</button>
                                        <div className="input-date-web" style={{ flexGrow: 1 }}></div>
                                        <button className="button" onClick={() => downloadSensorReport()}>ดาวน์โหลด</button>
                                    </div>
                                </div>

                                <div className="content-report">
                                    {
                                        DATA_REPORT ? (
                                            <table className="table-report" border="0" cellSpacing="-1" cellPadding="0">
                                                <thead style={{ position: "sticky", zIndex: 10, top: 0, left: 0, background: "white" }}>
                                                    <tr>
                                                        <th rowSpan={3} style={{ minWidth: 80, position: "sticky", zIndex: 10, top: 0, left: 0, background: "white" }}>
                                                            <div className="bg-cell-table-report" style={{ height: 60 }}>วันที่</div>
                                                        </th>
                                                        <th colSpan={DATA_REPORT.reports[0].headers.length} style={{ position: "sticky", zIndex: 9, top: 0 }}>
                                                            <div className="bg-cell-table-report" style={{ position: "absolute" }}></div>
                                                            <div style={{ position: "sticky", zIndex: 9, top: 0, left: "calc(50% + 20px)", width: "fit-content" }}>เวลา</div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        {
                                                            DATA_REPORT.reports[0].headers.map((time, i) => {
                                                                return ((i % 4) === 0) ? (<th key={i} colSpan={4} style={{ fontSize: 10, position: "sticky", zIndex: 9, top: 0 }}>
                                                                    <div className="bg-cell-table-report">{time.x_label}</div>
                                                                </th>) : (null)
                                                            })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        {
                                                            DATA_REPORT.reports[0].headers.map((time, i) => {
                                                                return <th key={i} style={{ fontSize: 10, position: "sticky", zIndex: 9, top: 0 }}><div className="bg-cell-table-report"></div></th>
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        DATA_REPORT.reports[0].rows.map((row, i) => {
                                                            return <tr key={i}
                                                                style={{ border: "2px solid" + (getColorWeekend(row.date) !== "#FFF" ? "#46e2fa" : null) }}>
                                                                <td style={{ fontSize: 10, position: "sticky", zIndex: 8, left: 0 }}>
                                                                    <div
                                                                        className="bg-cell-table-report"
                                                                        style={{
                                                                            background: getColorWeekend(row.date),
                                                                            border: (getColorWeekend(row.date) !== "#FFF" ? "none" : null)
                                                                        }}>
                                                                        {row.date}
                                                                    </div>
                                                                </td>
                                                                {
                                                                    row.values.map((value, j) => {
                                                                        return <td key={j} title={value.y_label} style={{ background: getColorStatus(value.y_status) }}></td>
                                                                    })
                                                                }
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        ) : (null)
                                    }
                                </div>

                                {
                                    (CONDITION_REPORT && CONDITION_REPORT.length > 0) ? (
                                        <div className="legend-label-container">
                                            {/* <div className="head-label">{REPORT_NAME}</div> */}
                                            {
                                                CONDITION_REPORT.map((label, index) => {
                                                    return (
                                                        <div className="legend" key={index}>
                                                            <div className="color" style={{
                                                                background: label.color ? label.color : "#444",
                                                                minHeight: label.size ? "unset" : 20,
                                                                height: label.size ? parseInt(label.size) : 20
                                                            }}></div>
                                                            <div className="text">{label.title}</div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    ) : (null)
                                }
                            </>

                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ModalReport;
