import "./styles.css";
import React from "react";
import closeIcon from "../../assets/icons/close.png";

import {
    useGlobalContext,
    setHintContent
} from "../../service/GlobalContext";

function HintContent() {

    const [globalState, setGlobalState] = useGlobalContext();

    const [stateTitleHint, setStateTitleHint] = React.useState("");
    const [stateContentHint, setStateContentHint] = React.useState("");

    function toggleContent() {
        if (globalState.hint_content) {
            setHintContent(setGlobalState, null);
            setStateTitleHint("");
            setStateContentHint("");
        }
    }

    React.useEffect(() => {
        const controller = new AbortController();

        if (globalState.hint_content && globalState.hint_content.content) {
            setStateTitleHint(globalState.hint_content.title);
            setStateContentHint(globalState.hint_content.content);
        }

        return () => {
            controller.abort();
        };
    }, [globalState.hint_content]);

    return (
        <div className={"hint-content " + (globalState.hint_content ? "active" : "")} onClick={() => toggleContent()}>
            <div className="content-box">
                <div className="header">
                    <div className="title">{stateTitleHint}</div>
                    <div className="bt-close" onClick={() => toggleContent()}><img src={closeIcon} /></div>
                </div>
                <div className="content">
                    {stateContentHint}
                </div>
            </div>
        </div>
    )
}

function HintButton(props) {

    const [globalState, setGlobalState] = useGlobalContext();

    function toggleContent() {
        if (globalState.hint_content) {
            setHintContent(setGlobalState, null);
        } else {
            setHintContent(setGlobalState, {
                title: props.title,
                content: props.content
            });
        }
    }

    // React.useEffect(() => {
    //     const controller = new AbortController();

    //     setHintContent(setGlobalState, stateHint);

    //     return () => {
    //         controller.abort();
    //     };
    // }, [props]);

    return (
        <div className="hint-button" onClick={() => toggleContent()}>!</div>
    );
}

export {
    HintButton,
    HintContent
};
